
import { Vue, Options } from 'vue-class-component';
import { BaseTextInput, BaseButton, BaseIcon, BaseCard, CardHeader } from '@/lib/components';
import { OrganisationRoleService } from '@/services/api';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    rolesPath: {
      type: String,
      required: true
    }
  },
  components: {
    CardHeader,
    BaseCard,
    BaseTextInput,
    BaseButton,
    BaseIcon
  }
})
export default class NewRolePage extends Vue {
  rolesPath!: string;
  name = '';
  errors: { [key: string]: Array<string> } = {};
  notificationStore = useNotificationStore();

  get orgRoleService(): OrganisationRoleService {
    return new OrganisationRoleService(this.$route.params.organisationId);
  }

  async save() {
    try {
      const response = await this.orgRoleService.create({
        name: this.name
      });
      this.notificationStore.addSuccessNotification({
        title: this.$t('platform.role.create-success')
      });
      this.$router.push({
        path: `${this.rolesPath}/${response.data.id}`
      });
    } catch (error) {
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.role.create-error')
      });
      this.errors = error.response.data.errors;
    }
  }

  cancel() {
    this.$router.back();
  }
}
