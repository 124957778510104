
import { Vue, Options } from 'vue-class-component';
import axios, { CancelTokenSource } from 'axios';
import { ButtonLink, BasePopover, SpecialityButton, PopoverLink, PopoverButton, BaseCard } from '@/lib/components';
import { OrganisationRoleService } from '@/services/api';
import { Role } from '@/models';
import { useProgressStore } from '@/stores/progress.store';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  components: {
    BaseCard,
    PopoverButton,
    PopoverLink,
    ButtonLink,
    BasePopover,
    SpecialityButton
  }
})
export default class RolesPage extends Vue {
  loading = true;
  progressStore = useProgressStore();
  notificationStore = useNotificationStore();

  roles: Array<Role> = [];
  errors: { [key: string]: Array<string> } = {};

  request: CancelTokenSource | null = null;

  orgRoleService!: OrganisationRoleService;

  get organisationId(): string {
    return this.$route.params.organisationId;
  }

  created() {
    this.progressStore.startProgress();
    this.orgRoleService = new OrganisationRoleService(this.organisationId);
    this.fetchRoles();
    this.$watch('organisationId', async () => {
      if (Object.keys(this.$route.query).length) {
        await this.$router.replace({ path: this.$route.path });
      }
      this.orgRoleService = new OrganisationRoleService(this.organisationId);
      await this.fetchRoles();
    });
  }

  unmounted() {
    this.progressStore.removeProgress();
    if (this.request) {
      this.request.cancel();
    }
  }

  async fetchRoles() {
    try {
      this.request = axios.CancelToken.source();
      this.roles = (
        await this.orgRoleService.index({
          cancelToken: this.request.token
        })
      ).data;
      this.request = null;
    } catch (e) {
      if (!axios.isCancel(e)) {
        this.progressStore.setError();
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.role.fetch-error')
        });
      }
    } finally {
      this.progressStore.finishProgress();
      this.loading = false;
    }
  }

  async deleteRole(roleId: string) {
    try {
      await this.orgRoleService.delete(roleId);
      this.notificationStore.addSuccessNotification({
        title: this.$t('platform.role.remove-success')
      });
      this.fetchRoles();
    } catch (err) {
      console.warn(err);
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.role.remove-error')
      });
    }
  }
}
